import { createContext, ReactNode, useContext, useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

export type UserData = {
  // Show a proceed to core app button
  is_marketplace_user: boolean;
  // Show a proceed to pharmacy app button
  is_pharmacy_user: boolean;
  intercom: {
    user_id: number;
    user_hash: string;
    created_at: string;
  };
};

export const UserContext = createContext<UserData | undefined>(undefined);

type UserProviderProps = {
  value: UserData | undefined;
  children: ReactNode;
};

export const UserProvider = ({ value, children }: UserProviderProps) => {
  const { update } = useIntercom();

  useEffect(() => {
    if (value?.intercom) {
      // Update intercom with user info if we have it
      update({
        userId: `${value.intercom.user_id}`,
        userHash: value.intercom.user_hash,
        createdAt: value.intercom.created_at,
      });
    }
  }, [update, value?.intercom]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);
