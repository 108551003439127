import { useState, useEffect } from 'react';

export const fetcher = async <T>(url: string): Promise<T> => {
  // Set up the proper headers
  const options = {
    method: 'GET',
    // Ensure any vetcove session credentials are included
    credentials: 'include' as RequestCredentials,
  };

  const response = await fetch(url, options);

  // Protecting against times where we are returned a text/html request even though
  // we asked for JSON
  const contentType = response.headers.get('content-type');
  if (contentType && !contentType.includes('application/json')) {
    throw new Error(
      'Trouble connecting to Vetcove. If this issue continues, please reach out to Vetcove via the icon in the bottom right corner.'
    );
  }

  // Get the JSON returned from the server, but don't try to parse empty content
  const json = response.status === 204 ? undefined : await response.json();

  if (!response.ok) {
    throw new Error(
      json?.detail ??
        'An unknown error occured. Please try again or reach out to Vetcove via the icon in the bottom right corner.'
    );
  }

  return json;
};

export const useFetcher = <T extends object>(url: string) => {
  const [data, setData] = useState<T | undefined>(undefined);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetcher<T>(url);
        setData(response);
      } catch (error) {
        setData(undefined);
      }
    };

    fetchData();
  }, [url]);

  return data;
};
