import '../styles/globals.css';
import '../styles/intercom.scss';
import type { AppProps } from 'next/app';
import { IntercomProvider } from 'react-use-intercom';

import { UserData, UserProvider } from 'context/user';
import { useFetcher } from 'utils/fetcher';
import { CORE_MARKETING_API_URL } from 'constants/urls';
import { INTERCOM_APP_ID } from 'constants/intercom';
import Scripts from 'components/scripts/Scripts';

function MyApp({ Component, pageProps }: AppProps) {
  const userData = useFetcher<UserData>(CORE_MARKETING_API_URL);

  return (
    <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={true}>
      <UserProvider value={userData}>
        <Component {...pageProps} />
        <Scripts />
      </UserProvider>
    </IntercomProvider>
  );
}
export default MyApp;
